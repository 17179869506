import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { delay } from "lodash";

const Transition = ({ children }) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const variants = {
        initial: { y: "100vh", opacity: 1 }, // Nová stránka začne mimo obrazovku
        animate: { y: 0, opacity: 1 }, // Nová stránka vyjede na své místo
        exit: { y: 0, opacity: 1, transition: {delay: 1.1}}, // Stará stránka zůstane na místě
    };

    return (
        isMounted && (
            <motion.div
                initial={variants.initial}
                animate={variants.animate}
                exit={variants.exit}
                transition={{
                    duration: 1.3, // Dlouhá animace pro hladký přechod
                    ease: "easeInOut",
                }}
                style={{
                    position: "absolute", // Zajišťuje, že nová stránka nebude mít vliv na layout ostatního obsahu
                    top: 0,
                    left: 0,
                    width: "100%",
                    minHeight: "100vh",
                    background: "white", // Pozadí nové stránky
                    zIndex: 10, // Nová stránka bude nad starou
                    overflow: "hidden", // Zajistí, že nic nevyteče ven
                }}
            >
                {children}
            </motion.div>
        )
    );
};

export default Transition;
