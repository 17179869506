import "./src/styles.css";
import React, { useState } from "react";
import Layout from "./src/components/Layout";
import { SidebarProvider } from "./src/components/SidebarContext";
import Transition from "./src/components/Transition";
import { AnimatePresence } from "framer-motion";




export const wrapRootElement = ({ element, props }) => {

  // Pokud stránka začíná "/about" nebo "/services"
  /*if (props.location.pathname.startsWith('/it-outsourcing') 
      || props.location.pathname.startsWith('/digitalizace')
      || props.location.pathname.startsWith('/audit')
      || props.location.pathname.startsWith('/monitoring')
      || props.location.pathname.startsWith('/bezpecnost')
      || props.location.pathname.startsWith('/cloud')
      || props.location.pathname.startsWith('/')
  ) 
  {
    return <SidebarProvider>{element}</SidebarProvider>;
  } */
  return <SidebarProvider>{element}</SidebarProvider>
  // Na ostatních stránkách layout nebude použit
  // return element;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <AnimatePresence  initial={false}>
      <Transition key={props.location.pathname}>{element}</Transition>
    </AnimatePresence>
  );
};