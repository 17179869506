import React, { createContext, useContext, useState, useEffect } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activeOption, setActiveOption] = useState(null)

    // Při načtení stránky zkontroluj localStorage pro existující stav
    useEffect(() => {
        const savedState = localStorage.getItem("isSidebarOpen");
        if (savedState !== null) {
        setIsSidebarOpen(JSON.parse(savedState));
        }
    }, []);

    // Ulož stav do localStorage při změně
    useEffect(() => {
        localStorage.setItem("isSidebarOpen", JSON.stringify(isSidebarOpen));
    }, [isSidebarOpen]);

    return (
        <SidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen, activeOption, setActiveOption }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    return useContext(SidebarContext);
  };