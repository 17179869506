exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audit-js": () => import("./../../../src/pages/audit.js" /* webpackChunkName: "component---src-pages-audit-js" */),
  "component---src-pages-backup-js": () => import("./../../../src/pages/backup.js" /* webpackChunkName: "component---src-pages-backup-js" */),
  "component---src-pages-bezpecnost-js": () => import("./../../../src/pages/bezpecnost.js" /* webpackChunkName: "component---src-pages-bezpecnost-js" */),
  "component---src-pages-cloud-js": () => import("./../../../src/pages/cloud.js" /* webpackChunkName: "component---src-pages-cloud-js" */),
  "component---src-pages-digitalizace-js": () => import("./../../../src/pages/digitalizace.js" /* webpackChunkName: "component---src-pages-digitalizace-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-outsourcing-js": () => import("./../../../src/pages/it-outsourcing.js" /* webpackChunkName: "component---src-pages-it-outsourcing-js" */),
  "component---src-pages-monitoring-js": () => import("./../../../src/pages/monitoring.js" /* webpackChunkName: "component---src-pages-monitoring-js" */)
}

