import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import * as styles from "./layout.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";
import { motion } from "framer-motion";
import Phone from "../components/PhoneIcon";
import { useSidebar } from "./SidebarContext";

const sidebarData = [
    { 
        title: "IT Outsourcing", 
        dropdown: [
            { title: "IT outsourcing", path: "/it-outsourcing/" },
            { title: "Digitalizace", path: "/digitalizace/" },
            { title: "IT Audit", path: "/audit/" },
            { title: "Monitoring", path: "/monitoring/" },
        ]
    },
    { 
        title: "Cyber security", 
        dropdown: [
            { title: "Bezpečnost dat", path: "/bezpecnost/" },
            { title: "Zálohování", path: "" },
            { title: "Ransomware", path: "" },
            { title: "Socialhacking", path: "" },
        ]
    },
    { 
        title: "Server & Cloud", 
        dropdown: [
            { title: "Cloud server hosting", path: "/cloud/" },
            { title: "Cloudový konfigurátor", path: "" },
            { title: "Enterprise řešení", path: "" },
        ]
    },
    { 
        title: "IT Projects", 
        dropdown: [
            { title: "A další IT projekty", path: "/dalsi-it-projekty" },
        ]
    },
    { title: "O nás", path: "", noDropdown: true },
    { title: "Kariéra", path: "", noDropdown: true },
    { title: "Kontakt", path: "", noDropdown: true },
];

export default function Layout({ children }) {
    const location = useLocation();
    const { isSidebarOpen } = useSidebar();
    const { activeOption } = useSidebar();

    useEffect(() => {
        if (location.pathname !== "/") {
            document.body.classList.add(styles.overflow);
        } else {
            const imageElement = document.body.querySelector(`.${styles.image}`);

            document.body.classList.remove(styles.overflow);

            document.body.querySelector(`.${styles.helpdeskButton}`).style.marginTop = "145px"

            if(imageElement){
                imageElement.style.display = 'none';
            }
        }

        return () => {
            document.body.classList.remove(styles.overflow);
        };
    }, [location]);

    const [openDropdown, setOpenDropdown] = useState(null);
    const [activeButtonIndex, setActiveButtonIndex] = useState(null);
    const [activeDropdownItemIndex, setActiveDropdownItemIndex] = useState(null);
    const [hoveredButtonIndex, setHoveredButtonIndex] = useState(null); // Nový stav pro sledování najetí na tlačítko
    const [isSidebarHovered, setIsSidebarHovered] = useState(false); // Stav pro detekci, zda kurzor je uvnitř sidebaru
    

    const buttonRefs = useRef([]); // Definice buttonRefs pro tlačítka
    const dropdownItemRefs = useRef([]); // Definice dropdownItemRefs pro položky dropdownu

    const handleClick = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
        setActiveButtonIndex(index);
    };

    const handleDropdownItemEnter = (index) => {
        setActiveDropdownItemIndex(index);
    };

    const handleDropdownItemLeave = () => {
        if (activeDropdownItemIndex !== null) {
            setActiveDropdownItemIndex(activeDropdownItemIndex);
        }
    };

    const handleButtonMouseEnter = (index) => {
        setHoveredButtonIndex(index); // Sledování najetí na tlačítko

    };

    const handleButtonMouseLeave = () => {
        setHoveredButtonIndex(null); // Resetování najetí, pokud už myš není na tlačítku
    };

    const getLinePosition = () => {
        const activeItem = buttonRefs.current.find((button) =>
            button?.classList.contains(styles.active)
        );

    
        console.log("Nalezené aktivní tlačítko:", activeItem);
    
        if (isSidebarHovered) {
            if (hoveredButtonIndex !== null) {
                const buttonItem = buttonRefs.current[hoveredButtonIndex];
                if (buttonItem) {
                    return buttonItem.offsetTop + buttonItem.offsetHeight / 2;
                }
            }
    
            if (activeDropdownItemIndex !== null) {
                const buttonItem = buttonRefs.current[activeButtonIndex];
                const dropdownItem = dropdownItemRefs.current[activeDropdownItemIndex];
    
                if (dropdownItem && buttonItem) {
                    return (
                        buttonItem.offsetTop +
                        buttonItem.offsetHeight / 2 +
                        80 +
                        60 * activeDropdownItemIndex +
                        20 * activeDropdownItemIndex
                    );
                }
            }
        }
    
        if (!isSidebarHovered && activeItem) {
            return activeItem.offsetTop + activeItem.offsetHeight / 2;
        }
        
        return 250;
    };
    
    const [linePosition, setLinePosition] = useState(() => getLinePosition());
    
    
    useEffect(() => {
        requestAnimationFrame(() => {
            const newPosition = getLinePosition();
            setLinePosition(newPosition);
            console.log("Nová pozice čárky nastavena na:", newPosition);
        });
    }, [activeOption]);
    return (
        <div className={styles.layoutContainer}>
            <div className={styles.mainContent}>{children}</div>

            <motion.div 
                className={styles.sidebar}
                initial={{ translateX: "100%" }}
                animate={{ translateX: isSidebarOpen ? "0%" : "100%" }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                onMouseEnter={() => setIsSidebarHovered(true)}
                onMouseLeave={() => {
                        setIsSidebarHovered(false);
                }}
            >
                <Link to="/">
                    <StaticImage src="../images/logo_revoIT_white_170x35.svg" className={styles.image} alt=""/>
                </Link>

                <button className={styles.helpdeskButton}>
                    <Phone className={styles.icon} />
                    <span className={styles.text}>HELPDESK</span>
                </button>

                <nav>
                    <ul>
                        {sidebarData.map((item, index) => (
                            <li
                                key={index}
                                className={styles.menuItem}
                            >
                                <button
                                    className={`${styles.menuButton} ${location.pathname === item.path || item.dropdown?.some(subItem => location.pathname === subItem.path) ? styles.active : ""} ${activeOption === index + 1 ? styles.active : ""} `}
                                    ref={(el) => buttonRefs.current[index] = el}
                                    onClick={() => handleClick(index)}
                                    onMouseEnter={() => handleButtonMouseEnter(index)}  // Sledování najetí na hlavní tlačítka
                                    onMouseLeave={handleButtonMouseLeave}  // Resetování hover efektu
                                >
                                    <Link
                                        to={item.path}
                                        className={location.pathname === item.path ? styles.active : ""}
                                    >
                                        {item.title}
                                    </Link>
                                </button>

                                {item.dropdown && openDropdown === index && (
                                    <motion.ul
                                        className={styles.dropdown}
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: "auto" }}
                                        exit={{ opacity: 0, height: 0 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        {item.dropdown.map((subItem, subIndex) => (
                                            <li key={subIndex}>
                                                <button
                                                    className={`${styles.dropdownItem} ${location.pathname === subItem.path ? styles.active : ""}`}
                                                    ref={(el) => dropdownItemRefs.current[subIndex] = el}
                                                    onMouseEnter={() => handleDropdownItemEnter(subIndex)}
                                                    onMouseLeave={handleDropdownItemLeave}
                                                >
                                                    <Link to={subItem.path}>
                                                        {subItem.title}
                                                    </Link>
                                                </button>
                                            </li>
                                        ))}
                                    </motion.ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>

                <motion.div 
                    className={styles.sidebarLine}
                    animate={{ top: linePosition }}
                    transition={{ type: "tween", stiffness: 300, damping: 20 }} 
                    onUpdate={(latest) => console.log("Aktuální top animace:", latest.top)}
                />
            </motion.div>
        </div>
    );
}
